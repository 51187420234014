

.App {
  
  max-width: 100%;
  margin: 0 0;

  background-image: url('/public/assets/images/bg.png');
  background-size: cover;
  background-position: center;
  background-color: #000000;
  color: #fff;
  position: relative;
  overflow: hidden;
}

.halo {
  position: absolute;
  width: 1px;
  height: 1px;
  background: rgba(255, 0, 0, 0.536); /* Semi-transparent orange */
  border-radius: 50%;
  pointer-events: none; /* Allows clicks to pass through */
  transform: translate(-50%, -50%); /* Centers the halo on the cursor */
  box-shadow: -100px 0 600px 200px rgba(247, 47, 2, 0.3); /* Creates a diffused glow effect */
  transition: background 0.3s ease, width 0.3s ease, height 0.3s ease;
  z-index: 0;
}


#projects h2{
  font-family: 'Orbitron', sans-serif;
  margin: 0;
  color: #ffc456;
  font-size: 2em;
  justify-self: center;
  text-align: center;
}

#projects {
  /* padding: 20px; */
  width: 100%;
  border-radius: 5px;
  margin-bottom: 20px;
  transition: box-shadow 0.3s ease, transform 0.3s ease;

}
.projects{
  width:100%;
  
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
} 


header {
  padding: 20px;
  background-image: url('/public/assets/images/banner-portrait.png');
  background-size: cover;
  background-position: right center;  
  
  text-align: left; /* Aligns the header to the left */
  height: 100vh;
  margin-bottom: 40px;
}
header h1 {
  font-family: 'Orbitron', sans-serif;
  margin: 0;
  font-size: 2.5em;
}

h2 {
  color: #FFFFFF;
  /* border-bottom: 2px solid #FFA500; */
  padding-bottom: 10px;
}

header p {
  font-family: 'Arial', sans-serif;
  margin: 0;
  font-size: 1.5em;
  color: #ffffff;

}

header h2{
  font-family: 'Orbitron', sans-serif;
  margin: 0;
  color:rgba(255, 255, 255, 0.7);
  font-size: 2em;

}



section {
  margin-bottom: 40px;
  float: left; /* Floats the sections to the right */
}

ul {
  list-style-type: none;
  padding: 0;
  display: flex; /* Arrange the list horizontally */
  flex-wrap: wrap; /* Wrap the items into multiple lines */
  flex-direction: row; /* Arrange the items in a column */
}


ul li {
  /* background: #FFD580; */
  margin: 5px;
  padding: 10px;
  border-radius: 5px;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
}


#links ul {
  list-style-type: none;
  padding: 0;
  display: flex; /* Arrange the list horizontally */
  flex-wrap: wrap; /* Wrap the items into multiple lines */
  flex-direction: column; /* Arrange the items in a column */
}


#links a {
  /* background: #FFD580; */
  color:white;
  size: 50;

}

#links {
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 20px;
  transition: box-shadow 0.3s ease, transform 0.3s ease;

}

h2 {
  font-family: 'Orbitron', sans-serif;
  margin: 0;
  color: #ffc456;
}
ul li:hover {
  box-shadow: 0 0 20px rgba(255, 165, 0, 0.7);
  transform: scale(1.05);
}

#about h1 {
  

  margin: 0;
  color: #ffc456;

  size: 50px;
  font-family: 'Orbitron', sans-serif;
}
#about {

  padding: 20px;
  border-radius: 5px;
  margin-bottom: 20px;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
}
#skills {
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 20px;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
}

a {
  color: #4682B4;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

#contact p a {
  color: #FFA500;
  transition: box-shadow 0.3s ease;
}

#contact p a:hover {
  box-shadow: 0 0 10px rgba(255, 165, 0, 0.7);
}
